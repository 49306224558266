import { request } from '@@/plugin-request';

export async function query(params) {
  return request('/api/contracts', {
    params,
  });
}

export async function getByCourse(params) {
  return request('/api/contracts/getByCourse', { params });
}

export async function getContractsByStudent({ id, params }) {
  return request(`/api/contracts/student/${id}`, {
    method: 'GET',
    params,
  });
}

export async function queryById(id) {
  return request(`/api/contracts/${id}`);
}

export async function get4PointsHistory(id) {
  return request(`/api/contracts/pointshistory/${id}`);
}

export async function update(payload) {
  return request('/api/contracts', {
    method: 'PUT',
    data: payload,
  });
}

export async function addDocument(payload) {
  return request('/api/contracts/document', {
    method: 'POST',
    data: payload,
  });
}

export async function deleteById(id) {
  return request(`/api/contracts/${id}`, {
    method: 'DELETE',
  });
}

export async function getPointsHistory(params) {
  return request('/api/contracts/pointshistory', {
    params,
  });
}

export async function getStudyReportContract(params) {
  return request('/api/contracts/studyReport', {
    method: 'GET',
    params,
  });
}

export async function getOverDueContractAndCount(params) {
  return request('/api/contracts/overdueContract', {
    method: 'GET',
    params,
  });
}

export async function getOverDueContract(params) {
  return request('/api/contracts/table', {
    method: 'GET',
    params,
  });
}

export async function submitPaymentApplication(data) {
  return request(`/api/paymentApplications/trigger`, {
    method: 'POST',
    data,
  });
}

export async function forceSubmitPaymentApplication(data) {
  return request(`/api/paymentApplications/trigger`, {
    method: 'POST',
    data: {
      ...data,
      overrideUsage: true,
    },
  });
}

export async function getHeadTeachers(params) {
  return request('/api/users/headTeacher/connect', {
    method: 'GET',
    params,
  });
}

export async function consumeNonRecurringContent(payload) {
  return request('/api/contracts/nonrecurring', {
    method: 'PUT',
    data: payload,
  });
}

export async function getAllLarkUser() {
  return request('/api/users/allLarkUser', {
    method: 'GET',
  });
}

export async function sendLarkApprovalForGiftCourse(payload) {
  return request('/api/contracts/approvalForGiftCourse', {
    method: 'POST',
    data: payload,
  });
}

export async function getProductById(id) {
  return request(`/api/products/${id}`);
}

export async function exportContractTable(payload) {
  return request('/api/contracts/export', {
    method: 'POST',
    data: payload,
  });
}

export async function get4Table(params) {
  return request('/api/contracts/table', {
    method: 'GET',
    params,
  });
}
