const currencyCountryMapping = {
  USD: ['US'],
  CAD: ['CA'],
  AUD: ['AU'],
  GBP: ['GB'],
  CZK: ['CZ'],
  EUR: ['DE', 'LT'],
};

const currencyClearingMapping = {
  USD: ['ACH', 'FEDWIRE'],
  CAD: [],
  AUD: ['BANK_TRANSFER', 'BPAY'],
  GBP: ['FASTER_PAYMENTS'],
};

module.exports = {
  currencyCountryMapping,
  currencyClearingMapping,
};
