// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import RiseOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/RiseOutlined';
import ClusterOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ClusterOutlined';
import UserOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/UserOutlined';
import UsergroupAddOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/UsergroupAddOutlined';
import HistoryOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/HistoryOutlined';
import IdcardOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/IdcardOutlined';
import AppstoreAddOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/AppstoreAddOutlined';
import RedEnvelopeOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/RedEnvelopeOutlined';
import FileTextOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/FileTextOutlined';
import RetweetOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/RetweetOutlined';
import ReadOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ReadOutlined';
import BankOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BankOutlined';
import CalendarOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/CalendarOutlined';
import FormOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/FormOutlined';
import ApiOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ApiOutlined';
import BookOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BookOutlined';
import VideoCameraOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/VideoCameraOutlined';
import MoneyCollectOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/MoneyCollectOutlined';
import MonitorOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/MonitorOutlined';
import SolutionOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import BarChartOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BarChartOutlined';
import MailOutlined from '/Users/hy/git/savvyuni/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/MailOutlined';
export default { SmileOutlined, RiseOutlined, ClusterOutlined, UserOutlined, UsergroupAddOutlined, HistoryOutlined, IdcardOutlined, AppstoreAddOutlined, RedEnvelopeOutlined, FileTextOutlined, RetweetOutlined, ReadOutlined, BankOutlined, CalendarOutlined, FormOutlined, ApiOutlined, BookOutlined, VideoCameraOutlined, MoneyCollectOutlined, MonitorOutlined, SolutionOutlined, BarChartOutlined, MailOutlined };
