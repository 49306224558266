import ContractSelect from '@/components/DropdownSelect/Contract';
import HeadTeacherSelect from '@/components/DropdownSelect/HeadTeacher';
import CustomDatePicker from '@/components/FormItems/DatePicker';
import { getById as getCourseById } from '@/pages/Course/service';
import AttributeSelect from '@/pages/Lesson/components/AttributeSelect';
import RawDurationView from '@/pages/Lesson/components/RawDurationView';
import { create, update } from '@/pages/Lesson/service';
import { getAdjustedProductUsage } from '@/utils/AttributeExchange';
import { getErrorMessageFromResponse } from '@/utils/utils';
import {
  ProForm,
  ProFormDateTimePicker,
  ProFormDependency,
  ProFormDigit,
  ProFormGroup,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import { Form, Spin, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useModel } from 'umi';
import { getContractById, getLessonCount } from './service';
import { calcAmountAvailable, timeChangeDisabled, wordChangeDisabled } from './utils';

const resetFields = [
  'course',
  'productService',
  'name',
  'topic',
  'teachers',
  'zoomRequired',
  'zoomLink',
];
const zoomDefaultOptions = [
  {
    label: '自动创建',
    value: 'yes',
  },
  {
    label: '不需要直播间',
    value: 'no',
  },
];

const zoomDefaultOptionsForBanKe = [
  {
    label: '会议室(Meeting)',
    value: 'meeting',
  },
  {
    label: '在线研讨会(Webinar)',
    value: 'webinar',
  },
];

const repeatedScheduleOptions = [
  {
    label: '天',
    value: 'days',
  },
  {
    label: '周',
    value: 'weeks',
  },
];

const CreateForm = ({ onFinish, courseId, studentId, initialValues = {} }) => {
  const {
    initialState: { currentUser, permissions },
  } = useModel('@@initialState');
  const formRef = useRef();
  const submitRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [currentContract, setCurrentContract] = useState(initialValues.contract);
  const [contractData, setContractData] = useState();
  // courseData 是班课的数据
  const [courseData, setCourseData] = useState();
  const [courseLoading, setCourseLoading] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [lessonParent, setLessonParent] = useState();
  const [zoomOptions, setZoomOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const clearStates = () => {
    setCurrentContract();
    setContractData();
    setSelectedAttribute();
    setLessonParent();
    setZoomOptions(zoomDefaultOptions);
    setTeacherOptions([]);
  };

  useEffect(() => {
    if (lessonParent) {
      const teacherList = lessonParent.teachers || lessonParent.users;
      if (teacherList) {
        const options = teacherList.map((t) => {
          return {
            value: t.id,
            label: `${t.chineseName || t.lastName + t.firstName} [微信：${t.wechatId || '未知'}] ${
              t.teacherStatus === 'resigned' ? '(已离职)' : ''
            }`,
            disabled: t.disabledInTeacherSelect,
          };
        });
        setTeacherOptions(options);
      }
    } else {
      setTeacherOptions([]);
    }
    return () => setTeacherOptions([]);
  }, [lessonParent]);

  useEffect(() => {
    if (courseId) {
      setCourseLoading(true);
      getCourseById(courseId)
        .then((c) => {
          setCourseData(c);
          setLessonParent(c);
          const courseNo = c.courseNo ? `【${c.courseNo}】 - ` : '';
          formRef?.current?.setFieldsValue({
            course: c.id,
          });
          if (!initialValues?.name) {
            getLessonCount({
              course: c.id,
            }).then((currentCount) => {
              formRef?.current?.setFieldsValue({
                name: `${courseNo}${c.name} - Lec ${currentCount + 1}`,
              });
            });
          }
        })
        .catch((e) => {
          message.error('加载课程信息出错');
        })
        .finally(() => {
          setCourseLoading(false);
        });
    } else {
      setCourseData();
    }
    return () => setCourseData();
  }, [courseId]);

  useEffect(() => {
    if (currentContract) {
      setZoomOptions(zoomDefaultOptions);
      setLoading(true);
      getContractById(currentContract)
        .then((c) => {
          setContractData(c);
        })
        .catch((e) => {
          message.error('加载合同信息出错');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setContractData();
      setZoomOptions([
        ...zoomDefaultOptions,
        {
          label: '使用外部链接',
          value: 'external',
        },
      ]);
    }
    return () => setContractData();
  }, [currentContract]);

  const buildLessonName = async (lessonParent, type) => {
    const student = contractData?.student;
    if (student) {
      const studentName = `${student.lastName} ${student.firstName} - `;
      if (['置换录播课', '置换直播合同', '无后续服务'].includes(type)) {
        const contractString = `${contractData.organizationContractId || contractData.contractId}`;
        return `【${type}】- ${studentName}${contractString === 'undefined' ? '' : contractString}`;
      } else if (lessonParent) {
        if (lessonParent.courseNo) {
          const courseNo = `【${lessonParent.courseNo}】 - `;
          const currentCount = await getLessonCount({
            course: lessonParent.id,
            student: studentId,
            contract: contractData.id,
          });
          return `${courseNo}${studentName}${lessonParent.name} - ${
            type === '迟到/旷课扣课时' ? type : `Lec ${currentCount + 1}`
          }`;
        } else {
          return `${studentName}${lessonParent.name}${type === '迟到/旷课扣课时' ? type : ''}`;
        }
      }
    } else {
      // 班课
      const courseNo = courseData.courseNo ? `【${courseData.courseNo}】 - ` : '';
      const currentCount = await getLessonCount({
        course: courseData.id,
      });
      return `${courseNo}${courseData.name} - Lec ${currentCount + 1}`;
    }
  };

  const onSubmit = async (values) => {
    if (!timezone) {
      throw new Error('未知时区，请刷新页面后重试。');
    }
    const data = { ...values };
    data.timezone = timezone;
    if (values.teachers && !Array.isArray(values.teachers)) {
      data.teachers = [values.teachers];
    }
    if (values.productAttribute && `${values.productAttribute}`.includes('-')) {
      const exchangeKey = values.productAttribute;
      const pair = values.productAttribute.split('-');
      const attrWanted = Number(pair[1]);
      let adjustAmount;
      if (selectedAttribute.chargingUnit === '分钟') {
        adjustAmount = values.duration;
      } else if (selectedAttribute.chargingUnit === '字') {
        adjustAmount = values.expectWordCount;
      } else {
        // 次
        adjustAmount = 1;
      }
      const newProductUsage = getAdjustedProductUsage({
        exchangeKey,
        usageData: contractData.productUsage,
        rules: contractData.products[0].exchangeRules,
        adjustAmount,
      });
      data.newProductUsage = newProductUsage;
      data.productAttribute = attrWanted;
      data.exchangeKey = exchangeKey;
    }
    if (submitRef.current === 0) {
      const cb = initialValues?.id ? update : create;
      let createNum = 1;
      if (data.repeatedSchedule && data.repeatedScheduleAmount > 0) {
        createNum = 1 + data.repeatedScheduleAmount;
      }
      let firstLesson;
      const startInTZ = dayjs(data.startAt).tz(timezone);
      for (let i = 1; i <= createNum; i++) {
        try {
          const payload = { ...data };
          if (data.startAt) {
            payload.startAt = startInTZ
              .clone()
              .add(i - 1, data.repeatedScheduleMethod)
              .utc()
              .toDate()
              .toISOString();
          }
          if (data.duration) {
            payload.endAt = startInTZ
              .clone()
              .add(i - 1, data.repeatedScheduleMethod)
              .add(data.duration, 'minutes')
              .utc()
              .toDate()
              .toISOString();
          }
          let name = initialValues?.name;
          if (!name) {
            name = await buildLessonName(lessonParent, payload.type);
          }
          payload.name = name;
          message.loading({
            content: `(${i}/${createNum})正在${initialValues?.id ? '更新' : '创建'}: ${i}. ${name}`,
            key: i,
          });
          const response = await cb({
            id: initialValues?.id,
            ...payload,
          });
          if (response.error) {
            throw new Error(`${i}. ${name}: ${response.message}`);
          }
          if (!response || !response.id) {
            throw new Error(`${i}. ${name}${initialValues?.id ? '更新' : '创建'}失败，请重试。`);
          }
          if (!firstLesson) {
            firstLesson = response;
          }
          message.success({
            content: `${i}. ${name}${initialValues?.id ? '更新' : '创建'}成功！`,
            key: i,
            duration: 3,
          });
        } catch (error) {
          message.error({ content: getErrorMessageFromResponse(error), key: i, duration: 5 });
        }
      }
      if (firstLesson) {
        onFinish?.(firstLesson);
      }
      submitRef.current = 0;
      return true;
    }
    return true;
  };

  return (
    <Spin spinning={loading || courseLoading} size="large" tip="努力地加载数据中...">
      <ProForm
        formRef={formRef}
        initialValues={{
          headTeacher: !courseId && currentUser.id,
          zoomMeetingType: 'meeting',
          type: 'regular',
          repeatedSchedule: false,
          repeatedScheduleAmount: 0,
          ...initialValues,
        }}
        onValuesChange={async (changedValue, values) => {
          if (changedValue.headTeacher) {
            clearStates();
            formRef.current?.resetFields(['contract', 'productAttribute', ...resetFields]);
          }
          if (changedValue.contract) {
            clearStates();
            formRef.current?.resetFields(['productAttribute', ...resetFields]);
            setCurrentContract(changedValue.contract);
          }
          if (changedValue.productAttribute) {
            setTeacherOptions([]);
            formRef.current?.resetFields([...resetFields]);
            let attrWanted = changedValue.productAttribute;
            if (`${changedValue.productAttribute}`.includes('-')) {
              const pair = changedValue.productAttribute.split('-');
              attrWanted = Number(pair[1]);
              formRef?.current?.setFieldsValue({
                repeatedSchedule: false,
                repeatedScheduleAmount: 0,
              });
            }
            const attribute = contractData?.products?.[0]?.productAttributes?.find?.(
              (pa) => pa.id === attrWanted,
            );
            if (attribute) {
              setSelectedAttribute(attribute);
              formRef?.current?.setFieldsValue({
                zoomRequired: attribute.chargingUnit === '分钟' ? 'yes' : 'no',
                zoomLink: null,
              });
            } else {
              setSelectedAttribute();
            }
          }
          if (changedValue.startAt || changedValue.duration) {
            if (initialValues && initialValues.zoom) {
              formRef?.current?.setFieldsValue({
                updateZoom: true,
                zoomRequired: 'yes',
              });
            }
            if (values.startAt && values.duration) {
              formRef?.current?.setFieldsValue({
                endAt: dayjs(values.startAt)
                  .add(values.duration, 'minutes')
                  .utc()
                  .toDate()
                  .toISOString(),
              });
            }
          }
          if (changedValue.productService) {
            // const selectedService = selectedAttribute?.productServiceItems?.find?.(
            //   (ps) => ps.id === changedValue.productService,
            // );
            const selectedService = contractData?.serviceItems?.find?.(
              (s) => s.serviceItem?.id === changedValue.productService,
            )?.serviceItem;
            setLessonParent(selectedService);
            const name = await buildLessonName(selectedService, values.type);
            formRef.current?.setFieldsValue({ name });
            if (selectedService?.teachers?.length === 1) {
              formRef?.current?.setFieldsValue({
                teachers: selectedService?.teachers?.[0]?.id,
              });
            }
          }
          if (changedValue.course) {
            let selectedCourse = courseData?.id
              ? courseData
              : contractData?.courses?.find?.((c) => c.course?.id === changedValue.course);
            if (selectedCourse.course) {
              selectedCourse = selectedCourse.course;
            }
            setLessonParent(selectedCourse);
            const name = await buildLessonName(selectedCourse, values.type);
            formRef.current?.setFieldsValue({ name });
            if (selectedCourse?.teachers?.length === 1) {
              formRef?.current?.setFieldsValue({
                teachers: selectedCourse?.teachers?.[0]?.id,
              });
            }
          }
          if (changedValue.type) {
            formRef.current?.setFieldsValue({
              zoomRequired: changedValue.type === 'regular' ? 'yes' : 'no',
            });
            const name = await buildLessonName(lessonParent, changedValue.type);
            formRef.current?.setFieldsValue({ name });
          }
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="headTeacher"
          label="合同班主任"
          hidden={!!courseId || !!initialValues?.id}
          fieldProps={{
            disabled: !!initialValues?.id,
          }}
          rules={[
            {
              required: !courseId,
              message: '请选择合同的班主任',
            },
          ]}
        >
          <HeadTeacherSelect />
        </Form.Item>
        <ProFormDependency name={['headTeacher']}>
          {({ headTeacher }) => {
            if (headTeacher && !courseId) {
              return (
                <Form.Item
                  name="contract"
                  label="排课合同"
                  rules={[
                    {
                      required: true,
                      message: '请选择排课合同',
                    },
                  ]}
                >
                  <ContractSelect
                    headTeacher={headTeacher}
                    student={studentId}
                    disabled={!!initialValues?.id}
                    ongoing={true}
                    category="一对一"
                  />
                </Form.Item>
              );
            }
          }}
        </ProFormDependency>
        <ProFormDependency name={['contract']}>
          {({ contract }) => {
            if (!courseId) {
              return (
                <Form.Item
                  name="productAttribute"
                  label="产品属性"
                  rules={[
                    {
                      required: true,
                      message: '请选择产品属性',
                    },
                  ]}
                >
                  <AttributeSelect
                    contract={contractData}
                    disabled={!contract || !!initialValues?.id}
                  />
                </Form.Item>
              );
            }
          }}
        </ProFormDependency>
        <ProFormRadio.Group
          name="type"
          label="课节类型"
          options={[
            {
              label: '常规上课',
              value: 'regular',
            },
            {
              label: '迟到/旷课扣课时',
              value: '迟到/旷课扣课时',
            },
            {
              label: '置换录播课',
              value: '置换录播课',
            },
            {
              label: '置换直播合同',
              value: '置换直播合同',
            },
            {
              label: '无后续服务',
              value: '无后续服务',
            },
          ]}
          hidden={!!courseId}
          rules={[
            {
              required: !courseId,
            },
          ]}
          disabled={!!initialValues?.id}
        />
        <ProFormDependency name={['productAttribute', 'type']}>
          {({ productAttribute, type }) => {
            const hideInForm = ['置换录播课', '置换直播合同', '无后续服务'].includes(type);
            if (hideInForm) return;
            if (productAttribute) {
              let attribute = selectedAttribute;
              if (!attribute) {
                let attrWanted = productAttribute;
                if (`${productAttribute}`.includes('-')) {
                  const pair = productAttribute.split('-');
                  attrWanted = Number(pair[1]);
                }
                attribute = contractData?.products?.[0]?.productAttributes?.find?.(
                  (pa) => pa.id === attrWanted,
                );
                setSelectedAttribute(attribute);
              }
              if (attribute) {
                if (attribute.type !== '学术') {
                  const options = contractData?.serviceItems
                    ?.filter?.((s) => s.productAttribute?.id === attribute.id)
                    .map((ps) => {
                      return {
                        value: ps.serviceItem.id,
                        label: `${ps.serviceItem.name} [学术等级：${
                          ps.serviceItem.academicLevel || '未知'
                        }]`,
                      };
                    });
                  return (
                    <ProFormSelect
                      name="productService"
                      label="服务项"
                      required
                      options={options}
                      disabled={!!initialValues?.id}
                    />
                  );
                } else {
                  const courses = contractData?.courses?.filter?.(
                    (c) => c.productAttribute?.id === attribute.id,
                  );
                  let options = [];
                  if (courses && courses.length) {
                    options = courses.map((c) => {
                      return {
                        value: c.course.id,
                        label: `【${c.course.courseNo || '未知课号'}】${c.course.name} [学术等级：${
                          c.course.academicLevel || '未知'
                        }] [课程类型：${c.course.type || '未知'}]`,
                      };
                    });
                  }
                  return (
                    <ProFormSelect
                      name="course"
                      label="课程"
                      required
                      options={options}
                      disabled={!!initialValues?.id}
                    />
                  );
                }
              }
            } else if (!courseId) {
              return (
                <ProFormSelect
                  name="dummyCourse"
                  label="课程/服务项"
                  required
                  placeholder="请先选择一个产品属性"
                />
              );
            }
          }}
        </ProFormDependency>
        <ProFormSelect
          name="course"
          label="课程名称"
          hidden={!courseId}
          options={[
            {
              value: courseData?.id,
              label: courseData?.name,
            },
          ]}
        />
        <ProFormDependency name={['course', 'productService', 'type']}>
          {({ course, productService, type }) => {
            if (!lessonParent) {
              if (productService) {
                const selectedService = selectedAttribute?.productServiceItems?.find?.(
                  (ps) => ps.id === productService,
                );
                if (selectedService) {
                  setLessonParent(selectedService);
                }
              } else {
                let selectedCourse = courseData?.id
                  ? courseData
                  : contractData?.courses?.find?.((c) => c.course?.id === course);
                if (selectedCourse?.course) {
                  selectedCourse = selectedCourse.course;
                }
                if (selectedCourse) {
                  setLessonParent(selectedCourse);
                }
              }
            }
            const product = contractData?.products?.[0];
            const consumptionUnit =
              product?.attributeDetails?.[selectedAttribute?.id]?.consumptionUnit;
            const minimumConsumption =
              product?.attributeDetails?.[selectedAttribute?.id]?.minimumConsumption;
            const special = ['置换录播课', '置换直播合同', '无后续服务'].includes(type);
            const wordCountRequired =
              selectedAttribute &&
              (selectedAttribute.chargingUnit === '字' || selectedAttribute.name?.includes('润色'));
            const hasEnoughPoints = (_, value) => {
              if (value && selectedAttribute) {
                if (selectedAttribute.chargingUnit === '字') {
                  if (consumptionUnit && value % consumptionUnit !== 0) {
                    return Promise.reject(`必须为消耗单位(${consumptionUnit}字)的整数倍`);
                  }
                  if (minimumConsumption && value < minimumConsumption) {
                    return Promise.reject(`必须大于单次最低消耗(${minimumConsumption}字)`);
                  }
                  const max = calcAmountAvailable({
                    contract: contractData,
                    productAttribute: formRef?.current?.getFieldValue('productAttribute'),
                    targetAttribute: selectedAttribute,
                    currentLesson: initialValues,
                  });
                  if (value > max) {
                    return Promise.reject('产品属性余额不足');
                  }
                } else {
                  if (
                    selectedAttribute?.chargingUnit === '分钟' &&
                    consumptionUnit &&
                    value % consumptionUnit !== 0
                  ) {
                    return Promise.reject(`必须为消耗单位(${consumptionUnit}分钟)的整数倍`);
                  }
                  const max = calcAmountAvailable({
                    contract: contractData,
                    productAttribute: formRef?.current?.getFieldValue('productAttribute'),
                    targetAttribute: selectedAttribute,
                    currentLesson: initialValues,
                  });
                  if (['次', '篇'].includes(selectedAttribute?.chargingUnit)) {
                    if (max < 1) {
                      return Promise.reject('产品属性余额不足');
                    }
                  } else {
                    if (value > max) {
                      return Promise.reject('产品属性余额不足');
                    }
                  }
                }
              }
              return Promise.resolve();
            };
            return (
              <>
                <ProFormText name="name" label="课节编号（自动生成）" readonly />
                <ProFormText
                  name="topic"
                  label={special ? '置换课程名/备注' : '课节主题'}
                  rules={[
                    {
                      required: special,
                    },
                  ]}
                />
                <ProFormSelect
                  name="teachers"
                  label="授课老师"
                  placeholder="请选择授课老师"
                  showSearch={true}
                  options={teacherOptions}
                  disabled={dayjs() > dayjs(initialValues.endAt)}
                  hidden={type !== 'regular'}
                  rules={[
                    {
                      required: type === 'regular',
                    },
                  ]}
                />
                <Form.Item
                  name="startAt"
                  label={special ? '置换/消耗时间' : '上课时间'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <CustomDatePicker
                    disabled={timeChangeDisabled({
                      currentUser,
                      permissions: permissions.lesson,
                      editing: !!initialValues?.id,
                      type: 'startAt',
                      values: initialValues,
                    })}
                    datepickerFieldProps={{
                      minuteStep: 5,
                    }}
                    onTimezoneChange={(tz) => {
                      setTimezone(tz);
                    }}
                  />
                </Form.Item>
                <ProFormGroup>
                  <Form.Item
                    name="duration"
                    label={type === 'regular' ? '预排课时' : '需消耗课时'}
                    rules={
                      !wordCountRequired
                        ? [
                            {
                              required: true,
                            },
                            {
                              validator: hasEnoughPoints,
                            },
                          ]
                        : []
                    }
                  >
                    <RawDurationView
                      disabled={timeChangeDisabled({
                        currentUser,
                        permissions: permissions.lesson,
                        editing: !!initialValues?.id,
                        type: 'duration',
                        values: initialValues,
                      })}
                      step={
                        selectedAttribute?.chargingUnit === '分钟' && consumptionUnit
                          ? consumptionUnit
                          : 1
                      }
                    />
                  </Form.Item>
                  <ProFormDigit
                    width="sm"
                    name="expectWordCount"
                    label="预计润色字数"
                    placeholder="请输入预计润色字数"
                    hidden={!!courseId}
                    disabled={wordChangeDisabled({
                      currentUser,
                      permissions: permissions.lesson,
                      editing: !!initialValues?.id,
                      type: 'expectWordCount',
                      values: initialValues,
                    })}
                    fieldProps={{
                      step:
                        selectedAttribute?.chargingUnit === '字' && consumptionUnit
                          ? consumptionUnit
                          : 1,
                    }}
                    rules={
                      wordCountRequired
                        ? [
                            {
                              required: true,
                            },
                            {
                              validator: hasEnoughPoints,
                            },
                          ]
                        : []
                    }
                  />
                </ProFormGroup>
                <ProFormDateTimePicker hidden name="endAt" label="结束时间" />
                <ProFormRadio.Group
                  name="updateZoom"
                  label="需要更新直播间"
                  hidden={!initialValues?.id}
                  disabled={initialValues?.startAt && new Date(initialValues.startAt) < new Date()}
                  rules={[{ required: !!initialValues?.id }]}
                  options={[
                    {
                      label: '是',
                      value: true,
                    },
                    {
                      label: '否',
                      value: false,
                    },
                  ]}
                />
                <ProFormDependency name={['updateZoom', 'type']}>
                  {({ updateZoom, type }) => {
                    const editing = !!initialValues?.id;
                    return (
                      <ProFormRadio.Group
                        name="zoomRequired"
                        label="直播间设置"
                        options={zoomOptions}
                        rules={[
                          { required: (!editing && type === 'regular') || (editing && updateZoom) },
                        ]}
                        hidden={editing && !updateZoom}
                        disabled={type !== 'regular'}
                      />
                    );
                  }}
                </ProFormDependency>
                <ProFormDependency name={['zoomRequired']}>
                  {({ zoomRequired }) => {
                    if (zoomRequired === 'external') {
                      return <ProFormText name="zoomLink" label="外部链接" required />;
                    }
                  }}
                </ProFormDependency>
                <ProFormDependency name={['zoomRequired']}>
                  {({ zoomRequired }) => {
                    const category = courseData ? courseData.category : '';
                    if (zoomRequired === 'yes' && ['赠课', '班课'].includes(category)) {
                      return (
                        <ProFormRadio.Group
                          name="zoomMeetingType"
                          label="选择教室类型"
                          options={zoomDefaultOptionsForBanKe}
                          value={'meeting'}
                          rules={[{ required: true }]}
                        />
                      );
                    }
                  }}
                </ProFormDependency>
                <ProFormGroup>
                  <ProFormDependency name={['productAttribute']}>
                    {({ productAttribute }) => {
                      return (
                        <ProFormRadio.Group
                          name="repeatedSchedule"
                          label="是否重复排课"
                          hidden={!!initialValues?.id || !!courseId}
                          disabled={productAttribute && String(productAttribute).includes('-')}
                          help={
                            productAttribute && String(productAttribute).includes('-')
                              ? '不支持排课时批量兑换'
                              : ''
                          }
                          options={[
                            {
                              label: '是',
                              value: true,
                            },
                            {
                              label: '否',
                              value: false,
                            },
                          ]}
                        />
                      );
                    }}
                  </ProFormDependency>
                  <ProFormDependency name={['repeatedSchedule']}>
                    {({ repeatedSchedule }) => {
                      if (repeatedSchedule) {
                        return (
                          <ProFormRadio.Group
                            name="repeatedScheduleMethod"
                            label="重复排课频率"
                            options={repeatedScheduleOptions}
                            rules={[{ required: repeatedSchedule }]}
                            hidden={initialValues?.id}
                          />
                        );
                      }
                    }}
                  </ProFormDependency>
                  <ProFormDependency
                    name={[
                      'repeatedSchedule',
                      'duration',
                      'expectWordCount',
                      'repeatedScheduleAmount',
                    ]}
                  >
                    {({ repeatedSchedule, duration, expectWordCount, repeatedScheduleAmount }) => {
                      if (repeatedSchedule) {
                        return (
                          <ProFormDigit
                            width="xs"
                            name="repeatedScheduleAmount"
                            label="重复次数"
                            placeholder="请输入重复次数"
                            help={`共创建${1 + repeatedScheduleAmount ?? 0}节课`}
                            rules={[
                              { required: true },
                              {
                                validator: async (_, value) => {
                                  if (value && selectedAttribute) {
                                    let usage = value + 1;
                                    if (selectedAttribute.chargingUnit === '字') {
                                      usage = (value + 1) * expectWordCount;
                                    } else if (selectedAttribute.chargingUnit === '分钟') {
                                      usage = (value + 1) * duration;
                                    }
                                    return hasEnoughPoints(null, usage);
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            fieldProps={{ precision: 0 }}
                            hidden={initialValues?.id}
                          />
                        );
                      }
                    }}
                  </ProFormDependency>
                </ProFormGroup>
              </>
            );
          }}
        </ProFormDependency>
      </ProForm>
    </Spin>
  );
};
export default CreateForm;
