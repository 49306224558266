import { getAmountByIntervalAndCurrency, getTableByType } from '@/utils/SalaryTierTransfer';
import { formatMinuteIntToHourMinuteText } from '@/utils/utils';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { ProTable } from '@ant-design/pro-components';
import { Badge, Button, Popover, Tag, message } from 'antd';
import { useRef } from 'react';
import { getAllSalaryIncreaseApprovalList, updateSalaryIncreaseApprovalStatus } from '../service';
const TeachersSalaryIncreaseTable = ({
  status,
  onFinish,
  setApprovalMadalOpen,
  setDataSource,
  setDurationModalVisible,
  setSalaryIncreaseTableRef,
  salaryIncreaseApprovalsPermission,
}) => {
  const actionRef = useRef();
  //拿到最新的薪酬方案
  function getLatestValidFrom(array) {
    let latestItem = null;
    let latestValidFrom = null;

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const validFrom = new Date(item.validFrom);

      if (!latestValidFrom || validFrom > latestValidFrom) {
        latestItem = item;
        latestValidFrom = validFrom;
      }
    }

    return latestItem;
  }

  function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const result = hours + '小时 ' + remainingMinutes + '分钟';
    return result;
  }

  const salaryLevel = {
    一档: '一档',
    二档: '二档',
    三档: '三档',
    四档: '四档',
    五档: '五档',
    六档: '六档',
    七档: '七档',
    八档: '八档',
  };
  const columns = [
    {
      title: '老师姓名',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        const teacherStatus = record.user?.teacherStatus === 'resigned' ? '(已离职)' : '';
        return (
          <Popover
            content={
              <div>
                <p>用户名：{record.user?.username}</p>
                <p>微信号：{record.user?.wechatId}</p>
                <p>
                  电话号码：+{record.user?.phonePrefix}-{record.user?.phone}
                </p>
              </div>
            }
            title="老师信息"
            trigger="click"
          >
            <a>
              {!!record.user?.chineseName
                ? `${record.user?.chineseName} ${teacherStatus}`
                : `${record.user?.lastName}${record.user?.firstName} ${teacherStatus}`}
            </a>
          </Popover>
        );
      },
    },
    {
      title: '挡位',
      dataIndex: 'salaryLevel',
      valueType: 'select',
      valueEnum: salaryLevel,
      key: 'salaryLevel',
      render: (_, record) => {
        if (record.currentSalaryLevel) {
          return <Tag color="blue">{record.currentSalaryLevel}</Tag>;
        }
        return <Tag>{record.user.teacherSalaryLevel}</Tag>;
      },
    },
    {
      title: '预计涨薪后挡位',
      dataIndex: 'targetSalaryLevel',
      key: 'targetSalaryLevel',
      valueType: 'select',
      valueEnum: salaryLevel,
      render: (_, record) => {
        if (record.targetSalaryLevel) {
          return <Tag color="green">{record.targetSalaryLevel}</Tag>;
        }
        return <Tag color="green">{record.user.teacherSalaryLevel}</Tag>;
      },
    },
    {
      title: '薪资',
      dataIndex: 'salary',
      key: 'salary',
      search: false,
      render: (_, record) => {
        if (record.currentAmount) {
          return (
            <span>
              {parseInt(record.currentAmount)}
              {`(${record.currentCurrency})`}
            </span>
          );
        }
        const salaryAllocation = getLatestValidFrom(record.user.salaryAllocations);
        return (
          <span>
            {salaryAllocation.amount}
            {`(${salaryAllocation.currency})`}
          </span>
        );
      },
    },
    {
      title: '预计涨薪后薪资',
      dataIndex: 'newSalary',
      key: 'newSalary',
      search: false,
      render: (_, record) => {
        //TODO:更改根据acdemicLevel判断
        const salaryAllocation = getLatestValidFrom(record.user.salaryAllocations);
        const table = getTableByType(salaryAllocation.academicLevel);

        const currentAmount = getAmountByIntervalAndCurrency(
          record.targetSalaryLevel,
          // salaryAllocation.currency,
          record.user.salaryCurrency,
          table,
        );
        return (
          <span>
            {currentAmount}
            {`(${record.user.salaryCurrency})`}
          </span>
        );
      },
    },
    {
      title: '本次升档所需课时',
      dataIndex: 'requiredDuration',
      key: 'requiredDuration',
      search: false,
      render: (_, record) => {
        if (record.duration) {
          if (record.approvalStatus === '已拒绝') {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{formatMinuteIntToHourMinuteText(record.duration)}</span>
                <span>
                  <strong>预计下次升档所需课时：</strong>
                  {formatMinuteIntToHourMinuteText(record.user.teacherUpgradeDuration)}
                </span>
              </div>
            );
          }
          return <span>{formatMinuteIntToHourMinuteText(record.duration)}</span>;
        }
        const requiredDuration = formatMinuteIntToHourMinuteText(
          record.user.teacherUpgradeDuration,
        );
        return <span>{requiredDuration}</span>;
      },
    },
    //触发升档时间
    {
      title: '触发升档时间',
      dataIndex: 'approvalStartTime',
      key: 'approvalStartTime',
      valueType: 'dateRange',
      render: (_, record) => {
        if (record.approvalStartTime) {
          return <span>{new Date(record.approvalStartTime).toLocaleString('zh-CN')}</span>;
        }
      },
    },
    //TODO:增加补贴金额
    {
      title: '审批状态',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
      valueType: 'select',
      search: !status,
      valueEnum: {
        审批中: '审批中',
        已通过: '已通过',
        已拒绝: '已拒绝',
      },

      render: (_, record) => {
        //根据status判断
        if (record.approvalStatus === '未发起') {
          return <Badge status="default" text="未发起" />;
        } else if (record.approvalStatus === '审批中') {
          return <Badge status="processing" text="审批中" />;
        } else if (record.approvalStatus === '已通过') {
          return <Badge status="success" text="已通过" />;
        } else if (record.approvalStatus === '已拒绝') {
          return <Badge status="error" text="已拒绝" />;
        }
      },
    },
    //操作
    {
      title: '操作',
      dataIndex: 'option',
      valueType: 'option',
      render: (_, record) => {
        if (!salaryIncreaseApprovalsPermission?.includes('update')) {
          return <LockOutlined />;
        }
        return (
          <>
            {record.approvalStatus === '未发起' ? (
              <Button
                type="link"
                disabled={record.approvalStatus !== '未发起'}
                onClick={async () => {
                  try {
                    await updateSalaryIncreaseApprovalStatus({
                      id: record.id,
                      approvalStatus: '审批中',
                    });
                    if (onFinish) {
                      onFinish();
                    }
                    actionRef.current?.reload();
                    message.success('发起审批成功');
                  } catch {
                    message.error('发起审批失败');
                  }
                }}
                icon={<EditOutlined />}
              >
                发起审批
              </Button>
            ) : (
              <div>
                <Button
                  type="link"
                  disabled={record.approvalStatus !== '审批中'}
                  onClick={() => {
                    const salaryAllocation = getLatestValidFrom(record.user.salaryAllocations);
                    const table = getTableByType(salaryAllocation.academicLevel);

                    const currentAmount = getAmountByIntervalAndCurrency(
                      record.targetSalaryLevel,
                      // salaryAllocation.currency,
                      record.user.salaryCurrency,
                      table,
                    );
                    //将之前的薪酬方案currency改为用户档案中的currency
                    const changeToUserCurrencyAmount = getAmountByIntervalAndCurrency(
                      record.user.teacherSalaryLevel,
                      record.user.salaryCurrency,
                      table,
                    );
                    setDataSource({
                      id: record.id,
                      validFrom: record.approvalStartTime,
                      salaryLevel: record.targetSalaryLevel,
                      currentAmount: currentAmount,
                      unit: '小时',
                      academicLevel: ['高中', '大学', '研究生'],
                      currency: record.user.salaryCurrency,
                      category: '一对一',
                      type: '学术辅导',
                      teacherId: record.user.id,
                      //之前的老师挡位
                      previousSalaryLevel: record.user.teacherSalaryLevel,
                      approvalStartTime: record.approvalStartTime,
                      //之前的薪资
                      previousAmount: salaryAllocation.amount,
                      //之前的货币
                      previousCurrency: salaryAllocation.currency,
                      //转换之前的薪资为用户档案中的货币
                      changeToUserCurrencyAmount: changeToUserCurrencyAmount,
                    });
                    //传入actionRef，用于刷新列表
                    setSalaryIncreaseTableRef(actionRef);
                    setApprovalMadalOpen(true);
                  }}
                  icon={<CheckCircleOutlined />}
                >
                  同意
                </Button>
                <Button
                  type="link"
                  disabled={record.approvalStatus !== '审批中'}
                  onClick={() => {
                    setDataSource({
                      id: record.id,
                      teacherId: record.user.id,
                      previousSalaryLevel: record.user.teacherSalaryLevel,
                    });
                    setDurationModalVisible(true);
                    setSalaryIncreaseTableRef(actionRef);
                  }}
                  icon={<CloseCircleOutlined />}
                >
                  不同意
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  if (!status) {
    //往columns中添加发起审批时间，在第六列后面
    columns.splice(
      6,
      0,
      {
        title: '补贴总金额',
        dataIndex: 'totalSubsidyAmount',
        key: 'totalSubsidyAmount',
        search: false,
        render: (_, record) => {
          if (!record.subsidyAmount) {
            return <span>-</span>;
          }
          return (
            <span>
              {record.subsidyAmount}
              {`(${record.targetCurrency})`}
            </span>
          );
        },
      },
      {
        title: '补贴总时长',
        dataIndex: 'totalSubsidyDuration',
        key: 'totalSubsidyDuration',
        search: false,
        render: (_, record) => {
          if (!record.subsidyDuration) {
            return <span>-</span>;
          }
          return <span>{convertMinutesToHours(record.subsidyDuration)}</span>;
        },
      },
      {
        title: '审批发起时间',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        valueType: 'dateRange',
        render: (_, record) => {
          return <span>{new Date(record.updatedAt).toLocaleString('zh-CN')}</span>;
        },
      },
    );
  }

  return (
    <ProTable
      id="teachersSalaryIncreaseTable"
      rowKey="id"
      columns={columns}
      request={async (params, sorter, filter) => {
        if (status) {
          params.approvalStatus = status;
        }
        const res = await getAllSalaryIncreaseApprovalList({
          ...params,
          current: params.current,
          pageSize: params.pageSize,
          // approvalStatus: status,
        });
        return {
          data: res.salaryIncreaseApproval,
          success: true,
          total: res.count,
        };
      }}
      actionRef={actionRef}
      search={{
        labelWidth: 'auto',
        defaultCollapsed: false,
      }}
    />
  );
};

export default TeachersSalaryIncreaseTable;
