import { request } from '@@/plugin-request';

export async function getWallet(params) {
  return request('/api/wallet', {
    method: 'GET',
    params,
  });
}

export async function getWithdraw(params) {
  return request('/api/withdraw', {
    method: 'GET',
    params,
  });
}

export async function createWithdraw(payload) {
  return request('/api/withdraw', {
    method: 'POST',
    data: payload,
  });
}

export async function getWithdrawLimit(params) {
  return request('/api/salary/withdrawLimit', {
    method: 'GET',
    params,
  });
}

export async function getOwnerSalarySettings(params) {
  return request('/api/users/salarySettings', {
    method: 'get',
    params,
  });
}
