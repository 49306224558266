import { frequentlyUsedTimezones, timezoneOptions } from '@/utils/tz';
import { ProForm, ProFormDateTimePicker, ProFormSelect } from '@ant-design/pro-components';
import { Button, Col, Divider, Modal, Row, message } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const TimezoneConverter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayAllTimes, setDisplayAllTImes] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [convertedTimes, setConvertedTimes] = useState([]);
  const [selectedTargetTimezone, setSelectedTargetTimezone] = useState([]);

  const handleFinish = () => {
    if (!selectedTime || !selectedTimezone || !selectedTargetTimezone?.length) {
      setConvertedTimes([]);
      return;
    }

    const inputTime = dayjs.tz(selectedTime, selectedTimezone);
    const outputTimes = selectedTargetTimezone.map((zone) => {
      const timeInZone = inputTime.tz(zone).format('YYYY-MM-DD HH:mm');
      const label = timezoneOptions.find((ele) => ele.value === zone).label;
      return { label, timeInZone };
    });

    setConvertedTimes(outputTimes);
  };

  let timezoneOptions_ = displayAllTimes ? timezoneOptions : frequentlyUsedTimezones;

  React.useEffect(() => {
    handleFinish();
  }, [selectedTime, selectedTimezone, displayAllTimes, selectedTargetTimezone]);

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>时区转换工具</Button>
      <Modal
        title="时区转换工具"
        open={isModalOpen}
        width={'50%'}
        onCancel={() => {
          setIsModalOpen(false);
          setConvertedTimes([]);
          setSelectedTargetTimezone([]);
          setSelectedTime(null);
          setSelectedTimezone(null);
          setDisplayAllTImes(false);
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Divider>起始时间</Divider>
        <Row justify="end">
          {displayAllTimes ? (
            <a onClick={() => setDisplayAllTImes(false)}>仅显示常用时区</a>
          ) : (
            <a
              onClick={() => {
                setDisplayAllTImes(true);
                message.success('已可选择所有时区');
              }}
            >
              找不到时区选项？
            </a>
          )}
        </Row>
        <ProForm
          onValuesChange={(changedValues, allValues) => {
            setSelectedTime(allValues.time);
            setSelectedTimezone(allValues.timezone);
          }}
          submitter={false}
        >
          <Row>
            <Col span={12}>
              <ProFormSelect
                style={{ width: 200 }}
                showSearch
                name="timezone"
                label="时间所在时区"
                options={timezoneOptions_}
              />
            </Col>
            <Col span={12}>
              <ProFormDateTimePicker
                style={{ width: 200 }}
                name="time"
                label="选择时间"
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
              />
            </Col>
          </Row>
        </ProForm>
        <Divider>转换结果</Divider>
        <ProFormSelect
          style={{ width: 200 }}
          showSearch
          name="timezone"
          label="目标时区"
          options={timezoneOptions_}
          mode="multiple"
          onChange={(val) => {
            setSelectedTargetTimezone(val);
          }}
        />
        {convertedTimes?.length ? (
          <div style={{ paddingTop: 18 }}>
            <table>
              <tbody>
                <tr key={0} style={{ marginBottom: 30 }}>
                  <td style={{ fontSize: 18, fontWeight: 'bold', paddingBottom: 20 }}>
                    {timezoneOptions_.find((ele) => ele.value === selectedTimezone).label}的
                  </td>
                  <td style={{ fontSize: 18, whiteSpace: 'pre', paddingBottom: 20 }}>
                    {dayjs.tz(selectedTime, selectedTimezone).format('YYYY-MM-DD HH:mm')} 等同于：
                  </td>
                </tr>
                {convertedTimes.map(({ label, timeInZone }, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: 18, fontWeight: 'bold', paddingRight: 50 }}>{label}:</td>
                    <td style={{ fontSize: 18 }}>{timeInZone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default TimezoneConverter;
