import {
  getAttrUsage,
  getContractUsablePoints,
  getExchangableAmount,
  roundToTwo,
} from '@/utils/AttributeExchange';
import { message } from 'antd';
import dayjs from 'dayjs';

export const isAdminUser = (user) => {
  if (!user || !user.roles || !user.roles.length) return false;
  return user.roles.some(
    (item) => (item.name && item.name.includes?.('admin')) || item.includes?.('admin'),
  );
};

export const timeChangeDisabled = ({ currentUser, permissions, editing, type, values }) => {
  if (!currentUser || !permissions) {
    return true;
  }
  // 新建课节时间可填
  if (!editing) {
    return false;
  }
  // 编辑课节
  if (type !== 'rawDuration') {
    const { duration, startAt } = values;
    // 如果没有值，可以编辑
    if (!startAt) return type === 'startAt';
    if (!duration) return type === 'duration';
    // 未来的课节可以修改上课时间和预排时长
    return dayjs(startAt).isBefore(dayjs());
  } else {
    // 真实上课时长取决于权限
    const canCreate = permissions.includes('actual-duration-create');
    const { rawDuration } = values;
    if (!rawDuration && canCreate) {
      return false;
    }
    const canEdit = permissions.includes('actual-duration-edit');
    if (rawDuration && canEdit) {
      return false;
    }
  }
  return true;
};

export const wordChangeDisabled = ({ currentUser, permissions, editing, type, values }) => {
  if (!currentUser || !permissions) {
    return true;
  }
  // 新建课节可填写
  if (!editing) {
    return false;
  }
  // 编辑课节
  // 未来的课节可以修改预排字数
  if (type !== 'rawWordCount') {
    const { expectWordCount, startAt } = values;
    if (!startAt) {
      return false;
    } else {
      if (dayjs(startAt).toDate() > new Date()) {
        return false;
      }
    }
  } else {
    // 真实字数取决于权限
    const canCreate = permissions.includes('word-count-create');
    const { rawWordCount } = values;
    if (!rawWordCount && canCreate) {
      return false;
    }
    const canEdit = permissions.includes('word-count-edit');
    if (rawWordCount && canEdit) {
      return false;
    }
  }
  return true;
};

export function calcAmountAvailable({
  contract,
  productAttribute,
  targetAttribute,
  currentLesson,
}) {
  let amountTaken = 0;
  if (currentLesson.id) {
    if (targetAttribute.chargingUnit === '分钟') {
      amountTaken = currentLesson.actualDuration || currentLesson.duration;
    } else if (targetAttribute.chargingUnit === '字') {
      amountTaken = currentLesson.wordCount || currentLesson.expectWordCount;
    } else {
      amountTaken = 1;
    }
  }
  const product = contract?.products[0];
  let attrWanted = productAttribute;
  let amountUsable = 0;
  if (`${productAttribute}`.includes('-')) {
    const pair = productAttribute.split('-');
    attrWanted = Number(pair[1]);
    amountUsable = getExchangableAmount(productAttribute, contract);
  } else {
    const { available } = getAttrUsage(attrWanted, contract);
    amountUsable = available;
    if (!targetAttribute.isFree) {
      const points_remained_in_contract = getContractUsablePoints(contract);
      amountUsable = Math.min(available, points_remained_in_contract);
    }
  }
  const productUsage = contract.productUsage[product.id];
  if (targetAttribute.isFree || `${productAttribute}`.includes('-')) {
    return roundToTwo(amountUsable + amountTaken);
  } else {
    return roundToTwo(
      (amountUsable / productUsage[targetAttribute.id].exchangeUnitPoint) *
        productUsage[targetAttribute.id].exchangeUnitQuantity +
      amountTaken
    );
  }
}

function convertDate(date) {
  let event = date;
  event = event.replaceAll('-', '');
  event = event.replaceAll(':', '');
  event = event.replaceAll('.', '');
  return event;
}

export function downloadLessonsAsICS(lessons) {
  const data = lessons.lessons;
  let result =
    'BEGIN:VCALENDAR\n' +
    'CALSCALE:GREGORIAN\n' +
    'METHOD:PUBLISH\n' +
    'PRODID:-//Cal//EN\n' +
    'VERSION:2.0\n';
  let counter = 1;
  data.map((d) => {
    const startAt = d.startAt;
    const endAt = d.endAt;
    const summary = d.name;
    const description = d.student?.fullName ? `学生是${d.student?.fullName}` : '';
    const event =
      'BEGIN:VEVENT\n' +
      `UID:Lesson-${counter}\n` +
      `DTSTART:${convertDate(startAt)}\n` +
      `DTEND:${convertDate(endAt)}\n` +
      `SUMMARY:${summary}\n` +
      `DESCRIPTION:${description}\n` +
      'END:VEVENT\n';
    result += event;
    counter = counter + 1;
  });
  result += 'END:VCALENDAR';

  const blob = new File([result], { type: 'text/plain;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = url;
  tempLink.setAttribute('download', `${new Date().toLocaleDateString('zh-CN')}_日历导出.ics`);
  tempLink.click();
  tempLink.remove();

  message.success({
    content: '日历导出成功',
    duration: 3,
  });
}
