import { Input, InputNumber } from 'antd';
import { useEffect, useState } from 'react';

const RawDurationView = ({ value, onChange, disabled = false, step = 1, ...rest }) => {
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  useEffect(() => {
    if (value) {
      const duration = Number(value);
      if (duration) {
        const h = Math.floor(duration / 60);
        const m = duration - h * 60;
        setHours(h);
        setMinutes(m);
      } else {
        if (value.hours) {
          setHours(Number(value.hours));
        }
        if (value.minutes) {
          setMinutes(Number(value.minutes));
        }
      }
    }
  }, [value]);

  const onHoursChange = (value) => {
    const duration = (value || 0) * 60 + (minutes || 0);
    onChange(duration);
  };
  const onMinitesChange = (value) => {
    const duration = (hours || 0) * 60 + (value || 0);
    onChange(duration);
  };
  return (
    <Input.Group>
      <InputNumber disabled={disabled} min={0} value={hours} onChange={onHoursChange} />
      <span style={{ marginLeft: 5, marginRight: 15 }}>小时</span>
      <InputNumber
        disabled={disabled}
        min={0}
        step={step}
        value={minutes}
        onChange={onMinitesChange}
      />
      <span style={{ marginLeft: 5 }}>分钟</span>
    </Input.Group>
  );
};

export default RawDurationView;
