import provinces from '@/utils/ConstantData/country_to_province.json';
import cities from '@/utils/ConstantData/province_to_city.json';
import { Input, Select } from 'antd';
import { useState } from 'react';

const Address = ({ value, onChange }) => {
  if (!value?.country) return <p>Please specify a country</p>;
  if (!provinces[value.country]) return <p>{value.country} is not a supported country</p>;
  const [street, setStreet] = useState(value?.street);
  const [city, setCity] = useState(value?.city);
  const [province, setProvince] = useState(value?.province);
  const [country, setCountry] = useState(value?.country);
  const [postalCode, setPostalCode] = useState(value?.postalCode);
  const onStreetChange = (e) => {
    setStreet(e.target.value);
    onChange({ ...value, street: e.target.value });
  };
  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value);
    onChange({ ...value, postalCode: e.target.value });
  };
  const onProvinceChange = (v) => {
    setProvince(v);
    setCity();
    onChange({ ...value, province: v, city: null });
  };
  const onCityChange = (v) => {
    setCity(v);
    onChange({ ...value, city: v });
  };
  const onCountryChange = (v) => {
    setCountry(v);
    setProvince();
    setCity();
    onChange({ ...value, country: v, province: null, city: null });
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span>Street:</span>
        <Input
          value={street}
          onChange={onStreetChange}
          style={{ width: '100%', maxWidth: '450px' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <div style={{ width: '35%' }}>
          <span style={{ marginRight: 5 }}>Prov:</span>
          <Select
            showSearch
            style={{ width: '80%', maxWidth: '200px' }}
            value={province}
            onChange={onProvinceChange}
            options={provinces[country].map((p) => {
              return { label: p.name, value: p.name };
            })}
          />
        </div>
        <div style={{ width: '35%' }}>
          <span style={{ marginRight: 5 }}>City:</span>
          <Select
            showSearch
            style={{ width: '80%', maxWidth: '200px' }}
            value={city}
            onChange={onCityChange}
            placeholder="Select a province first"
            options={
              !!province ? cities[country]?.[province]?.map((c) => ({ label: c, value: c })) : []
            }
          />
        </div>
        <div style={{ width: '30%' }}>
          <span style={{ marginRight: 5 }}>Country:</span>
          <Select
            showSearch
            style={{ width: '50%', maxWidth: '100px' }}
            value={country}
            onChange={onCountryChange}
            options={[
              { label: 'CA', value: 'CA' },
              { label: 'US', value: 'US' },
              { label: 'AU', value: 'AU' },
              { label: 'GB', value: 'GB' },
              { label: 'DE', value: 'DE' },
              { label: 'CZ', value: 'CZ' },
              { label: 'ES', value: 'ES' },
            ]}
          />
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span>Postal Code:</span>
        <Input
          value={postalCode}
          onChange={onPostalCodeChange}
          style={{ width: '20%', minWidth: '150px', maxWidth: '200px' }}
        />
      </div>
    </div>
  );
};

export default Address;
