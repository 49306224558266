import { request } from '@@/plugin-request';

export async function createOperationKuaKuaBonus(payload) {
  return request('/api/wechat/operation/kuaKuaBonus', {
    method: 'POST',
    data: payload,
  });
}

//verifyErpUser
export async function verifyErpUser(payload) {
  return request('/api/wechat/verifyErpUser', {
    method: 'POST',
    data: payload,
  });
}

//getHeadTeachers
export async function getHeadTeachers(params) {
  return request('/api/wechat/headTeachers', {
    method: 'GET',
    params,
  });
}

export async function getHeadTeacherStudent(params) {
  return request('/api/wechat/headTeacherStudent', {
    method: 'GET',
    params,
  });
}

export async function getAllSales(params) {
  return request('/api/wechat/sales', {
    method: 'GET',
    params,
  });
}

export async function getConsultantByStudentId(params) {
  return request('/api/wechat/consultant', {
    method: 'GET',
    params,
  });
}

export async function getUploadParams(params) {
  return request('/api/wechat/upload', {
    method: 'GET',
    params,
  });
}

//生成关联二维码
export async function generateRelationQrCode(params) {
  return request('/api/wechat/generateWechatQrCode', {
    method: 'GET',
    params,
  });
}
