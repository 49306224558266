import { request } from '@@/plugin-request';

export async function getTeamsByCategory(params) {
  return request('/api/team/teams', {
    method: 'GET',
    params,
  });
}

export async function getUsersForTeam(params) {
  return request('/api/team/users', {
    method: 'POST',
    data: params,
  });
}

export async function createTeam(params) {
  return request('/api/team', {
    method: 'POST',
    data: params,
  });
}

export async function updateTeam(params) {
  return request('/api/team', {
    method: 'PUT',
    data: params,
  });
}

export async function deleteTeam(params) {
  return request('/api/team', {
    method: 'DELETE',
    data: params,
  });
}

//获取销售团队的渠道
export async function fetchSalesChannelsByUsers(params) {
  return request('/api/salesData/fetchSalesChannelsByUsers', {
    method: 'GET',
    params,
  });
}
