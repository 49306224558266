// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/Users/hy/git/savvyuni/intl-erp/src/models/login';
import model_5 from '/Users/hy/git/savvyuni/intl-erp/src/.umi-production/plugin-initialState/@@initialState';
import model_2 from '/Users/hy/git/savvyuni/intl-erp/src/models/notice';
import model_3 from '/Users/hy/git/savvyuni/intl-erp/src/models/onboarding';
import model_4 from '/Users/hy/git/savvyuni/intl-erp/src/models/sales';
import { useQiankunStateForSlave as model_6 } from '/Users/hy/git/savvyuni/intl-erp/src/app';

export const models = {
model_1: { namespace: 'login', model: model_1 },
model_5: { namespace: '@@initialState', model: model_5 },
model_2: { namespace: 'notice', model: model_2 },
model_3: { namespace: 'onboarding', model: model_3 },
model_4: { namespace: 'sales', model: model_4 },
model_6: { namespace: '@@qiankunStateForSlave', model: model_6 },
} as const
