import {
  getAttrUsage,
  getContractUsablePoints,
  getExchangableAmount,
} from '@/utils/AttributeExchange';
import { formatMinuteIntToHourMinuteText } from '@/utils/utils';
import { TreeSelect } from 'antd';
import styles from '../index.less';

function genTreeStruct({ contract, ...rest }) {
  if (!contract) return [];
  const product = contract.products[0];
  const productUsage = { ...contract.productUsage };
  const attributes = product.productAttributes?.filter((pa) => pa.type !== '开通');
  if (!attributes || !attributes.length) return [];
  const totalRemainingPoints = getContractUsablePoints(contract);
  const attributeDetails = product.attributeDetails || {};
  const exchangeRules = product.exchangeRules || {};
  const treeStruct = [];
  attributes.forEach((pa) => {
    if (!attributeDetails[pa.id]) return;
    if (!productUsage[product.id][pa.id]) return;
    if (pa.category === '班课') {
      treeStruct.push({
        title: `${pa.name}（班课类型的课节应该在课程管理页面创建）`,
        value: pa.id,
        disabled: true,
      });
      return;
    }
    const { exchangeUnitPoint, exchangeUnitQuantity } = productUsage[product.id][pa.id];
    const displayUnit = pa.chargingUnit === '分钟' ? '小时' : pa.chargingUnit;
    const {
      available: usable,
      unavailable: used,
      reservedMinutes,
      reservedWords,
    } = getAttrUsage(pa.id, contract);
    let remaining = usable;
    if (exchangeUnitPoint) {
      remaining =
        (Math.max(0, Math.min(remaining, totalRemainingPoints)) / exchangeUnitPoint) *
        exchangeUnitQuantity;
    }
    const exchangeOptions = [];
    const keys = Object.keys(exchangeRules);
    keys.forEach((key) => {
      const pair = key.split('-');
      if (pair[1] === `${pa.id}` && exchangeRules[key]) {
        let amountUsable = getExchangableAmount(key, contract) || 0;
        if (displayUnit === '小时') {
          amountUsable = formatMinuteIntToHourMinuteText(amountUsable);
        }
        const fromAttr = attributes.find((a) => pair[0] === `${a.id}`);
        const ruleTitle = `使用【${fromAttr.name}】兑换${pa.name} [最多可换${amountUsable}${
          displayUnit !== '小时' ? displayUnit : ''
        }]`;
        exchangeOptions.push({
          title: ruleTitle,
          value: key,
          disabled: amountUsable <= 0 || remaining > 0,
        });
      }
    });
    let displayRemaining = remaining;
    let displayUsed = exchangeUnitPoint ? (used / exchangeUnitPoint) * exchangeUnitQuantity : used;
    if (displayUnit === '小时') {
      displayRemaining = formatMinuteIntToHourMinuteText(displayRemaining);
      displayUsed = formatMinuteIntToHourMinuteText(displayUsed);
    }
    let overuseWarning = false;
    if (exchangeUnitPoint > 0 && used > 0) {
      const total = ((used + usable) / exchangeUnitPoint) * exchangeUnitQuantity;
      if (pa.chargingUnit === '分钟' && reservedMinutes > 0) {
        if (reservedMinutes / total >= 0.9) {
          overuseWarning = true;
        }
      } else if (pa.chargingUnit === '字' && reservedWords > 0) {
        if (reservedWords / total >= 0.9) {
          overuseWarning = true;
        }
      }
    }
    const title = `${pa.name} [${
      exchangeUnitPoint
        ? `${exchangeUnitPoint}分/${exchangeUnitQuantity}${pa.chargingUnit}`
        : '免费'
    }] - 已排${displayUsed}${
      displayUnit !== '小时' ? displayUnit : ''
    } - 最多还可排${displayRemaining}${displayUnit !== '小时' ? displayUnit : ''}${
      overuseWarning ? ' (预排未使用占比超过90%，合同有上超时风险)' : ''
    }`;
    treeStruct.push({
      title,
      value: pa.id,
      // disabled: remaining <= 0,
      // 暂时做只能选两个的限制 + 已经选了一个服务项，就不能选第二个服务项
      disabled:
        remaining <= 0 ||
        // 已经选了一个服务项，就不能选第二个服务项
        (Array.isArray(rest.paSelected) &&
          rest.paSelected?.filter((pa) => pa.type !== '学术').length >= 1 &&
          pa.type !== '学术' &&
          !rest.paSelected?.map((r) => r.id).includes(pa.id)) ||
        // 暂时做只能选两个的限制
        (Array.isArray(rest.paSelected) &&
          rest.paSelected?.length >= rest.maxSelectCount &&
          !rest.paSelected?.map((r) => r.id).includes(pa.id)),
      children: exchangeOptions,
      className: overuseWarning ? styles.warning : '',
    });
  });
  return treeStruct;
}

export default ({ contract, multiple, ...rest }) => {
  const disabled = !contract || !contract.products;
  const treeStruct = genTreeStruct({ contract, ...rest });
  return (
    <TreeSelect
      multiple={!!multiple}
      disabled={disabled}
      placeholder={disabled ? '请先选择合同' : '请选择一个产品属性'}
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeData={treeStruct}
      treeDefaultExpandAll
      {...rest}
    />
  );
};
