import { cn, utcOffset } from '@h6you/timezone-names-cn';

export const timezoneOptions = Object.entries(cn).map((elem) => {
  const [tz, zhName] = elem;
  if (tz === 'Asia/Shanghai') {
    return {
      label: `亚洲/北京 ${tz}`,
      value: tz,
    };
  }
  return {
    label: `${zhName} ${tz}`,
    value: tz,
  };
});

const frequentlyUsed = [
  'Asia/Shanghai',
  'America/Toronto',
  'America/Vancouver',
  'America/Edmonton',
  'America/Winnipeg',
  'America/New_York',
  'America/Chicago',
  'Canada/Newfoundland',
  'America/Halifax',
  'Europe/London',
  'Australia/Sydney',
  'Australia/Perth',
  'Australia/Adelaide',
  'Pacific/Auckland',
];

export const frequentlyUsedTimezones = frequentlyUsed.map((tz) => {
  const offset = Math.round(utcOffset(tz) / 6) / 10;
  const offsetString = `${offset < 0 ? offset : `+${offset}`}`;
  if (tz === 'Asia/Shanghai') {
    return {
      label: `亚洲/北京(GMT${offsetString})`,
      value: tz,
    };
  }
  if (tz === 'America/Vancouver') {
    return {
      label: `美洲/温哥华/圣地亚哥(GMT${offsetString})`,
      value: tz,
    };
  }
  const zhName = cn[tz];
  return {
    label: `${zhName}(GMT${offsetString})`,
    value: tz,
  };
});
