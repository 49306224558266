import { request } from '@@/plugin-request';

export async function query(params) {
  return request('/api/users', {
    method: 'get',
    params,
  });
}
export async function getRoleOptions(params) {
  return request('/api/users/roleOptions', {
    method: 'get',
    params,
  });
}
export async function create(params) {
  return request('/api/users', {
    method: 'post',
    data: params,
  });
}

export async function update(params) {
  return request('/api/users', {
    method: 'put',
    data: params,
  });
}

export async function queryUnFinishedContracts(params) {
  return request(`/api/users/unfinishedContract`, {
    method: 'get',
    params,
  });
}

export async function changeHeadTeacher(payload) {
  return request(`/api/contracts/changeHeadTeacher`, {
    method: 'PUT',
    data: payload,
  });
}

export async function getAllHeadTeachers(params) {
  return request('/api/users/headTeacher/connect', {
    method: 'GET',
    params,
  });
}

export async function queryTeacherWithSalary(params) {
  return request('/api/users/salary', {
    method: 'get',
    params,
  });
}

export async function getFirstLessonReviewCount(params) {
  return request('/api/users/firstLessonReviewCount', {
    method: 'get',
    params,
  });
}

export async function getAll() {
  return request('/api/course/getAll');
}
